@import 'globals.scss';
.quill {
	div {
		div {
			p {
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
			p:first-child {
				margin-top: 0;
			}
			font-size: 16px;
			line-height: 1.8;
			font-weight: 300;
			strong {
				font-weight: 600;
			}
			font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
				'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
		}
	}
}
